import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import qs from 'qs'
import { Redirect, Route, withRouter } from 'react-router'
import Loader from 'components/Loader/Loader'
import NotificationWrapper from 'components/NotificationWrapper/NotificationWrapper'
import { routes } from './config'
import { checkSubscriptionExpire } from 'helpers/auth'
import { getCompanySubscription, clearSubscription } from 'store/action/company'
import { logout, triggerNoAccessModal } from 'store/action/auth'
import ValidateSubscription from 'components/ValidateSubscription/ValidateSubscription'
import ChromeBar from 'components/ChromeExtension/ChromeBar/ChromeBar'
import ChromeModal from 'components/ChromeExtension/ChromeModal/ChromeModal'

const PrivateRoute = ({
  user,
  companyId,
  company,
  subscription,
  getCompanySubscription,
  clearSubscription,
  location,
  logout,
  history,
  triggerNoAccessModal,
  ...routeProps
}) => {
  if (!user.firebase_uid || user.id === 'anonymous') {
    return <Redirect to={routes.login.path} />
  }

  if (!companyId && routeProps.companyRequired) {
    return <Redirect to={routes.businessoragency.path} />
  }

  return (
    <>
      {/* <ChromeModal /> */}
      <NotificationWrapper>
        <ValidateSubscription>
          <Route {...routeProps} />
        </ValidateSubscription>
      </NotificationWrapper>
    </>
  )
}

const mapStateToProps = ({ auth, company }, ownProps) => ({
  user: auth.user,
  subscription: company.companySubscription,
  company: auth?.user?.company,
  companyId: auth?.user?.companyId,
  ...ownProps,
})
export default connect(mapStateToProps, {
  getCompanySubscription,
  clearSubscription,
  logout,
  triggerNoAccessModal,
})(withRouter(PrivateRoute))
